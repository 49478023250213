import React from "react";
import { List, Avatar, PageHeader, Card, Table, notification, Popover, Form, Input, Button, Tooltip } from 'antd';
import Icon, { UserOutlined, SaveTwoTone } from '@ant-design/icons';
import reqwest from 'reqwest';
import Moment from 'react-moment';
import { map, every } from 'lodash';
import { Chart } from 'react-charts'
import { Sparklines, SparklinesBars } from 'react-sparklines';
import { isMobile } from 'react-device-detect';
import avatar_picture from '../images/avatar';

const { Search } = Input;

const UserNameChange = ({ user, token }) => {
  const onFinish = values => {
    if (values.internal_name === undefined)
      return

    reqwest({
      url: `/api/users/${user.id}`,
      method: 'put',
      headers: { 'authorization': `Token ${token}` },
      data: { user: values },
      type: 'json',
    })
    .then(_data => notification['success']({ message: 'Имя пользователя обновлено' }))
    .catch(() => notification['error']({ message: 'Не удалось обновить имя пользователя' }));
  };

  return (
    <Form layout="inline" initialValues={{ internal_name: user.internal_name }} onFinish={onFinish}>
      <Form.Item>
        <Form.Item name="internal_name" style={{ display: 'inline-block', width: 'calc(100% - 90px)', marginRight: '5px' }}>
          <Input placeholder='Добавить имя' prefix={<UserOutlined />} />
        </Form.Item>
        <Form.Item style={{ display: 'inline-block', width: '50px', marginRight: '0px' }}>
          <Tooltip placement='right' title='Сохранить'><Button type="dashed" htmlType="submit"><SaveTwoTone /></Button></Tooltip>
        </Form.Item>
      </Form.Item>
    </Form>
  );
};

// const PlayedGamesChart = ({ chart_data, user }) => {
//   if (user.played_games == null || user.played_games.length == 0)
//     return <div></div>;

//   const data = React.useMemo(
//     () => [
//       {
//         label: user.username,
//         data: user.played_games
//       }
//     ],
//     []
//   )

//   const axes = React.useMemo(
//     () => [
//       { primary: true, type: 'linear', position: 'bottom' },
//       { type: 'linear', position: 'left' }
//     ],
//     []
//   )

//   // https://www.npmjs.com/package/react-charts
//   return (
//     <div
//       style={{
//         width: '600px',
//         height: '400px'
//       }}
//     >
//       <Chart data={data} axes={axes} />
//     </div>
//   )
// };

const Last24Hours = ({ chart_data, user, ...theRest }) => {
  if (every(chart_data, a => a === 0)) {
    return 'нет';
  }

  const spark = <Sparklines data={chart_data} {...theRest}>
    <SparklinesBars style={{ stroke: "white", strokeWidth: "1", fill: "#40c0f5" }} />
  </Sparklines>
  return spark;
  // const content = <PlayedGamesChart user={user}/>
  // return <Popover placement='left' content={content} title="Все сыграные партии"> {spark} </Popover>
}

class Users extends React.Component {
  onPageChange = (current, pageSize) => {
    this.setState({ pageSize: pageSize }, this.fetch);
  }

  state = {
    roles: [],
    data: [],
    search: '',
    pageSize: 10,
    pagination: { showSizeChanger: true, onShowSizeChange: this.onPageChange, },
    sorter: {},
    filters: { messenger_type: null, id: null },
    loading: false,
  };

  componentDidMount() {
    this.fetch();
  }

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;

    this.setState({
      pagination: pager,
      sorter: sorter,
      filters: filters
    }, this.fetch);
  };

  onSearch = value => {
    this.setState({ search: value }, this.fetch);
  };

  fetch = () => {
    this.setState({ loading: true });

    reqwest({
      url: '/api/users',
      method: 'get',
      headers: { 'authorization': `Token ${this.props.token}` },
      data: {
        per_page: 10,
        search: this.state.search,
        per_page: this.state.pageSize,
        page: this.state.pagination.current,
        sortField: this.state.sorter.field,
        sortOrder: this.state.sorter.order,
        filters: this.state.filters,
      },
      type: 'json',
    }).then(data => {
      const pagination = { ...this.state.pagination };
      // Read total count from server
      pagination.total = data.total_count;
      this.setState({
        loading: false,
        roles: data.roles,
        data: data.users,
        pagination,
      });
    }).catch(() => notification['error']({ message: 'Ошибка...' }));
  };


  renderTable(data, pagination, loading) {
    const columns = [
      {
        title: 'ID',
        dataIndex: 'id',
        sorter: true,
        width: '20px',
      },
      {
        title: 'Имя в игре',
        dataIndex: 'username',
        width: '120px',
        sorter: true
      },
      {
        title: 'Внутреннее имя',
        width: '250px',
        dataIndex: 'internal_name',
        render: (_internal_name, record) => <UserNameChange token={this.props.token} user={record}/>,
      },
      {
        title: 'Сум. счёт',
        dataIndex: 'total_score',
        width: '120px',
        sorter: true
      },
      {
        title: 'Макс. счёт',
        dataIndex: 'max_score',
        width: '120px',
        sorter: true
      },
      {
        title: 'Монеты',
        width: '80px',
        dataIndex: 'coins_balance',
        sorter: true
      },
      {
        title: 'Сыграно',
        width: '100px',
        dataIndex: 'plays_count',
        sorter: true
      },
      {
        title: 'Модель',
        width: '140px',
        dataIndex: 'device_name'
      },
      {
        title: 'Инфо',
        dataIndex: 'device_info'
      },
      {
        title: '24 часа',
        dataIndex: 'last_24hours',
        width: '110px',
        render: (chart_data, record) => <Last24Hours chart_data={chart_data} user={record}/>,
      },
      {
        title: 'Онлайн',
        width: '200px',
        render: (time, _record) => time ? <span><Moment fromNow date={time} /></span> : 'никода',
        dataIndex: 'last_activity'
      }
    ];

    return <Table
      columns={columns}
      rowKey={record => record.id}
      dataSource={data}
      pagination={pagination}
      loading={loading}
      onChange={this.handleTableChange}
    />
  }

  renderList(data, pagination, loading) {
    // extra={<PlayedGamesChart user={user}/>}
    return <List
      itemLayout='vertical'
      loading={loading}
      pagination={pagination}
      dataSource={data}
      renderItem={user => (
        <List.Item
          key={user.id}
        >
          <List.Item.Meta
            avatar={<Avatar src={avatar_picture} />}
            title={user.username}
            description={<span>Онлайн: <Moment fromNow date={user.last_activity} /></span>}
          />
            <Last24Hours height={35} chart_data={user.last_24hours} user={user}/>
            <div>Макс. счёт: {user.max_score}</div>
            <div>Суммарный счёт: {user.total_score}</div>
            <div>Монеты: {user.coins_balance}</div>
            <div>Сграно раз: {user.plays_count}</div>
            <div>Модель телефона: {user.device_name}</div>
            <div>Инфо устройства: {user.device_info}</div>
            <div style={{ display: 'inline-flex' }}>Внутреннее имя: <UserNameChange token={this.props.token} user={user}/></div>
        </List.Item>
      )}
    />
  }
  render() {
    const { roles, data, pagination, loading } = this.state;

    return (
      <>
        <PageHeader
          className="site-page-header"
          title="Пользователи"
          subTitle="Список пользователей в игре"
        />
        <Card style={{ marginBottom: '1em' }}>
          <Search style={{ display: 'inline-block' }} placeholder="Поиск по имени" onSearch={this.onSearch} enterButton />
        </Card>
        <Card>
          {isMobile ? this.renderList(data, pagination, loading) : this.renderTable(data, pagination, loading)}
        </Card>
      </>
    );
  }
}

export default Users
