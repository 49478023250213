import React, { useState } from "react";
import { PageHeader, Card, Form, Input, InputNumber, Button, Col, Row, Tooltip, notification } from 'antd';
import reqwest from 'reqwest';

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 20 },
  },
};
const formItemLayoutWithOutLabel = {
  wrapperCol: {
    xs: { span: 24, offset: 0 },
    sm: { span: 20, offset: 0 },
    md: { span: 8, offset: 0 },
  },
};

const MyForm = ({ settings, token }) => {
  const onFinish = values => {
    reqwest({
      url: `/api/settings`,
      method: 'put',
      headers: { 'authorization': `Token ${token}` },
      data: { settings: values },
      type: 'json',
    })
    .then(_data => notification['success']({ message: 'Настройки обновлены' }))
    .catch(() => notification['error']({ message: 'Не удалось обновить настройки' }));
  };
  if (Object.keys(settings).length === 0) {
    return null;
  }

  return (
    <>
      <PageHeader className="site-page-header" title="Настройки" />

      <Card>
        <Form onFinish={onFinish} {...formItemLayoutWithOutLabel} initialValues={settings}>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Сохранить
            </Button>
          </Form.Item>
          <Form.Item name="initial_speed" label="Начальная скорость полёта" rules={[{ required: true, message: 'Надо заполнить' }]}>
            <InputNumber />
          </Form.Item>
          <Form.Item name="max_speed" label="Максимальная скорость полёта" rules={[{ required: true, message: 'Надо заполнить' }]}>
            <InputNumber />
          </Form.Item>
          <br/>
          <Form.Item name="birds_speed" label="Скорость полёта птиц" rules={[{ required: true, message: 'Надо заполнить' }]}>
            <InputNumber />
          </Form.Item>
          <Form.Item name="start_birds_score" label="Счет при котором начинаются птицы" rules={[{ required: true, message: 'Надо заполнить' }]}>
            <InputNumber />
          </Form.Item>
          <Form.Item name="more_birds_score" label="Счет при котором птиц становится больше" rules={[{ required: true, message: 'Надо заполнить' }]}>
            <InputNumber />
          </Form.Item>
        </Form>
      </Card>
    </>
  );
};


class SettingsPage extends React.Component {
  state = { settings: {} }

  componentDidMount() {
    fetch('/api/settings', {
      headers: { "Content-Type": "application/json", 'authorization': `Token ${this.props.token}` }
    }).then(response => {
      if (response.ok) {
        return response.json();
      }
      throw new Error("Network response was not ok.");
    })
    .then(({ settings }) => this.setState({ settings }))
    .catch(() => notification['error']({ message: 'Ошибка на странице настроек' }));
  }

  render() {
    const { settings } = this.state

    if (settings) {
      return <MyForm settings={settings} token={this.props.token} />
    } else {
      return <>Загрузка...</>
    }
  }
}

export default SettingsPage;
