import React, { useState } from "react";
import { Layout, Menu } from 'antd';
import useGlobal from "../store";
import { find } from 'lodash';
import { BrowserRouter as Router, Route, Switch, Redirect, Link } from "react-router-dom";
import { LogoutOutlined, UserOutlined, SettingOutlined } from '@ant-design/icons';
import { isMobile } from "react-device-detect";

import Users from "../components/Users";
import Settings from "../components/Settings";

const { Content, Sider } = Layout;
const allKeys = ['out', '/', '/settings']

export default () => {
  const [globalState, globalActions] = useGlobal();

  const pathname = window.location.pathname;
  const currentKey = find(allKeys, key => pathname.startsWith(key));
  const [selectedKeys, setSelectedKeys] = useState([currentKey]);
  const [smallScreen, setSmallScreen] = useState(isMobile || window.innerWidth < 1200); // Slider's breakpoint="xl" is 1200 width[]

  return (
    <Router>
      <Layout>
        <Sider
          breakpoint="xl"
          collapsedWidth="0"
          onCollapse={(collapsed, _type) => setSmallScreen(collapsed)}
          style={{
            overflow: (smallScreen ? '' : 'auto'),
            zIndex: 100,
            height: '100vh',
            position: 'fixed',
            left: 0,
          }}
        >
          <Menu theme="dark"
                mode="inline"
                onClick={({ _item, key, _keyPath, _domEvent }) => setSelectedKeys([key])}
                selectedKeys={selectedKeys}
          >
            <Menu.Item key="/">
              <Link to='/'>
                <UserOutlined />
                <span className="nav-text">Пользователи</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="/settings">
              <Link to='/settings'>
                <SettingOutlined />
                <span className="nav-text">Настройки</span>
              </Link>
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item key="out" onClick={() => globalActions.signOut()}>
              <LogoutOutlined />
              <span className="nav-text">Выйти</span>
            </Menu.Item>
          </Menu>
        </Sider>
        <Layout className="site-layout" style={{ marginLeft: smallScreen ? 0 : 200 }}>
          <Content style={{ overflow: 'initial', padding: '0px 24px 24px 24px' }}>
            <Switch>
              <Route exact path="/" exact>
                <Users token={globalState.token} />
              </Route>
              <Route exact path="/settings" exact>
                <Settings token={globalState.token} />
              </Route>
              <Redirect to="/" />
            </Switch>
          </Content>
        </Layout>
      </Layout>
    </Router>
  );
};
