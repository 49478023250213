import React from "react";
import useGlobal from "../store";
import Routes from "../routes/Index";
import Login from "./Login";

const App = () => {
  const [globalState, globalActions] = useGlobal();

  if (globalState.token && globalState.token.length > 0) {
    return <Routes />
  } else {
    return <Login />
  }
};

export default App;
